// sp: special
// txt: text

.light-sm-txt {
  font-size: 12px;
  font-weight: 300;
}

.light-md-txt {
  font-size: 14px;
  font-weight: 300;
}

.light-lg-txt {
  font-size: 16px;
  font-weight: 300;
}

.light-xl-txt {
  font-size: 20px;
  font-weight: 300;
}

.regular-sm-txt {
  font-size: 12px;
  font-weight: 400;
}

.regular-md-txt {
  font-size: 14px;
  font-weight: 400;
}

.regular-lg-txt {
  font-size: 16px;
  font-weight: 400;
}

.regular-xl-txt {
  font-size: 20px;
  font-weight: 400;
}

.medium-sm-txt {
  font-size: 12px;
  font-weight: 500;
}

.medium-md-txt {
  font-size: 14px;
  font-weight: 500;
}

.medium-lg-txt {
  font-size: 16px;
  font-weight: 500;
}

.medium-xl-txt {
  font-size: 20px;
  font-weight: 500;
}

.semiBold-sm-txt {
  font-size: 12px;
  font-weight: 600;
}

.semiBold-md-txt {
  font-size: 14px;
  font-weight: 600;
}

.semiBold-lg-txt {
  font-size: 16px;
  font-weight: 600;
}

.semiBold-xl-txt {
  font-size: 20px;
  font-weight: 600;
}

.semiBold-title-txt {
  font-size: 42px;
  font-weight: 600;
}

.bold-sm-txt {
  font-size: 12px;
  font-weight: bold;
}

.bold-md-txt {
  font-size: 14px;
  font-weight: bold;
}

.bold-lg-txt {
  font-size: 16px;
  font-weight: bold;
}

.bold-xl-txt {
  font-size: 20px;
  font-weight: bold;
}

.bold-subtitle-txt {
  font-size: 50px;
  font-weight: bold;
}

.bold-title-txt {
  font-size: 72px;
  font-weight: bold;
}
